import {
  AppBar,
  makeStyles,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useRouter } from 'next/router';
import React from 'react';
import { Button, Link } from './Link';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type Props = {
  children: React.ReactElement;
};

const ElevationScroll = ({ children }: Props): React.ReactElement => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const AppBarScroll: React.FC = () => {
  const styles = useStyles({});
  const router = useRouter();

  return (
    <>
      <ElevationScroll>
        <AppBar>
          <Toolbar className={styles.toolbar}>
            {router.pathname !== '/' && router.pathname !== '/ru' ? (
              <Button href="/" color="inherit" aria-label="goto index">
                <ArrowBackIcon />
              </Button>
            ) : (
              <div />
            )}
            <Typography variant="h3" component="span" noWrap>
              <Link href="/" color="inherit">
                OSA: online sales agent
              </Link>
            </Typography>
            {router.pathname === '/login' ? (
              <Button
                href="/registration/[user_type]"
                as="/registration/rtt"
                color="inherit"
                variant="text"
              >
                Реєстрація
              </Button>
            ) : (
              <Button href="/login" color="inherit" variant="text">
                Увійти
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </>
  );
};

export default AppBarScroll;
